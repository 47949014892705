/*App Layout Styles*/

#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(242,244,248)
}

.app-container {
  @include display-flex(flex, row, nowrap);
  width: 100%;
}

.app-sidebar {
  @include display-flex(flex, column, nowrap);
  width: $side-nav-width;
  min-width: $side-nav-width;
  max-width: $side-nav-width;
}

.app-main-container {
  @include display-flex(flex, column, nowrap);
  width: 100%;
  overflow: hidden;
}

.app-header {
  min-height: $app-bar-height;
}

.app-main-content-wrapper {
  flex: 1;
  @include display-flex(flex, column, nowrap);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-container {
  &.fixed-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $side-nav-width;
      right: 0;

      @media screen and (max-width: 1199px) {
        left: 0;
        right: 0;
      }
    }
  }
}

.app-container {
  &.collapsible-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: 0;
      right: 0;
    }
  }
}

.app-container {
  &.mini-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $mini-drawer-width;
      right: 0;
    }

    .app-sidebar {
      //@include flex(0, 0, $mini-drawer-width);
      width: $mini-drawer-width;
      min-width: $mini-drawer-width;
      max-width: $mini-drawer-width;
    }
  }
}
