.terminal-container {
  & .count-container:nth-child(4n + 1) {
    & .card {
      background: color('green', 'base');
    }
  }
  & .count-container:nth-child(4n + 2) {
    & .card {
      background: color("teal", "base")
    }
  }
  & .count-container:nth-child(4n + 3) {
    & .card {
      background: color('pink', 'base');
    }
  }
  & .count-container:nth-child(4n + 4) {
    & .card {
      background: color('cyan', 'base');
    }
  }
}