/*Theme Blue Styles*/
$app-primary-blue: #2196f3 !default;
$secondary-blue: #ff6e40;

$sidebar-blue: $white !default;
$sidebar-text-blue: #868e96 !default;

//-Base-scss
.blue .right-arrow {
  color: $app-primary-blue;
  &:after {
    color: $app-primary-blue;
  }
}

//- Bootstrap file Style
.blue {
  & a,
  & a.text-primary {
    color: $app-primary-blue;
    &:focus,
    &:hover {
      color: darken($app-primary-blue, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-blue !important;
  }

  & .page-link {
    color: $app-primary-blue;
    &:focus,
    &:hover {
      color: $app-primary-blue;
    }
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-blue !important;
    color: $sidebar-blue !important;
  }

  & .btn-primary {
    background-color: $app-primary-blue;
    border-color: $app-primary-blue;
    color: $sidebar-blue;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-blue, 5%) !important;
      border-color: darken($app-primary-blue, 5%) !important;
      color: $sidebar-blue !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #bbdefb !important;
    }

    &.lighten-3 {
      background-color: #90caf9 !important;
    }

    &.lighten-2 {
      background-color: #64b5f6 !important;
    }

    &.lighten-1 {
      background-color: #42a5f5 !important;
    }

    &.darken-1 {
      background-color: #1e88e5 !important;
    }

    &.darken-2 {
      background-color: #1976d2 !important;
    }

    &.darken-3 {
      background-color: #1565c0 !important;
    }

    &.darken-4 {
      background-color: #0d47a1 !important;
    }

    &.accent-1 {
      background-color: #82b1ff !important;
    }

    &.accent-2 {
      background-color: #448aff !important;
    }

    &.accent-3 {
      background-color: #2979ff !important;
    }

    &.accent-4 {
      background-color: #2962ff !important;
    }
  }
}

//Secondary
.blue {
  & a.text-secondary {
    color: $secondary-blue;
    &:focus,
    &:hover {
      color: darken($secondary-blue, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-blue !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-blue !important;
    color: $sidebar-blue !important;
  }

  & .btn-secondary {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
    color: $sidebar-blue;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-blue, 5%) !important;
      border-color: darken($secondary-blue, 5%) !important;
      color: $sidebar-blue !important;
    }
  }
}

//_header.scss
.blue .app-main-header {
  background-color: $app-primary-blue !important;

  & .search-bar input {
    background-color: lighten($app-primary-blue, 5%);

    &:focus {
      background-color: $white;
    }
  }

  & .search-dropdown input {
    @media screen and (max-width: 575px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.blue .color-theme-header {
  background-color: $app-primary-blue;
}

//_sidebar.scss
.blue .side-nav {
  background-color: $sidebar-blue !important;
  color: $sidebar-text-blue !important;
  @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));

  & .user-profile {
    background-color: $sidebar-blue;
    @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));
    position: relative;
    z-index: 2;
  }

  & .customizer {
    border-bottom: solid 1px lighten($sidebar-text-blue, 35%);
  }

  & .user-detail {
    & .user-name {
      color: $app-primary-blue;
    }
  }
}

.blue ul.nav-menu li a {
  color: $sidebar-text-blue;
}

.blue ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-blue;
  }
}

.blue ul.nav-menu > li.open > a,
.blue ul.nav-menu > li > a:hover,
.blue ul.nav-menu > li > a:focus {
  background-color: $sidebar-blue;
  color: $app-primary-blue;
}

.blue ul.nav-menu li.menu.open > a {
  border-color: $app-primary-blue;
}

.blue ul.nav-menu li.menu > a:focus:before,
.blue ul.nav-menu li.menu > a:hover:before {
  color: $app-primary-blue;
}

.blue ul.nav-menu li ul {
  background-color: $sidebar-blue;
}

.blue ul.nav-menu li.menu > a:before {
  color: $sidebar-text-blue;
}

.blue ul.nav-menu li.menu.open > a:before,
.blue ul.nav-menu li.menu > a:focus:before,
.blue ul.nav-menu li.menu li > a:hover:before {
  color: $app-primary-blue;
}

.blue ul.nav-menu li.menu .sub-menu li > a:before {
  color: $sidebar-text-blue;
}

.blue ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: $sidebar-blue;
  color: $app-primary-blue;
}

.blue ul.nav-menu li.menu .sub-menu li > a:hover,
.blue ul.nav-menu li.menu .sub-menu li > a:focus,
.blue ul.nav-menu li.menu.open .sub-menu li.active > a:before,
.blue ul.nav-menu li.menu .sub-menu li > a:focus:before,
.blue ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: $sidebar-blue;
  color: $app-primary-blue;
}

//_app-module.scss
.blue .module-nav {
  & li a {
    &.active {
      border-color: $app-primary-blue;
    }
  }
}

//_calendar.scss
.blue .rbc-event {
  background-color: $app-primary-blue;
}

.blue .rbc-event.rbc-selected {
  background-color: darken($app-primary-blue, 10%);
}

.blue .rbc-slot-selection {
  background-color: rgba($app-primary-blue, 0.7);
}

.blue .rbc-toolbar button:active,
.blue .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:active:hover,
.blue .rbc-toolbar button.rbc-active:hover,
.blue .rbc-toolbar button:active:focus,
.blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:focus {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.blue .rbc-toolbar button:hover {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

//_chat.scss
.blue .chat-sidenav-title {
  color: $app-primary-blue;
}

.blue .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-blue, 45%);
  }
}

//_dashboard.scss
.blue .contact-list {
  & i {
    color: $app-primary-blue;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.blue .Collapsible__trigger {
  background: $app-primary-blue;
}

.blue .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-blue;
  }
}

//_login.scss
.blue .login-content .form-control {
  &:focus {
    border-color: $app-primary-blue;
  }
}

//_portfolio.scss
.blue .filter-with-bg-color ul li {
  & a {
    border-color: $app-primary-blue;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-blue;
    }
  }
}

//_card.scss
.blue .profile-intro {
  & .icon {
    color: $app-primary-blue;
  }
}

.blue .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-blue;
  }
}

//_tables.scss
.blue .actions {
  color: $secondary-blue;
}

.blue .table-hover tbody tr:hover {
  background-color: rgba($app-primary-blue, 0.075);
}

//Border Color

.blue .border-primary {
  border-color: $app-primary-blue !important;
}

// login page content

.blue .app-logo-content {
  background-color: $app-primary-blue;
}

.blue .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-blue;
    color: $app-primary-blue;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-blue;
    }
  }
}
