.img-button-div {
  position: absolute;
  display: inline-flex;
}

.vat-date-picker {
  padding-top: 17px;
}

.slider-button-div {
  margin-left: 100px;
  position: absolute;
  display: inline-flex;
}

.img-align button {
  display: none;
}

.slider-image {
  height: 100%;
  width: 100%;
}

.popup-slider-img-align {
  width: 600px;
  height: 500px;
}

.popup-slider-img-align button {
  display: none;
}

.popup-slider-img-align:hover button {
  display: block;
  position: absolute;
}



.uploader-img-align button {
  display: none;
}

// .slider-img-align button {
//   display: none;
// }
// .slider-img-align:hover button {
//   display: flex;
//   position: relative;
// }
.img-align:hover button {
  display: block;
  position: relative;
}

.uploader-img-align:hover button {
  display: block;
  position: absolute;
}

.img-align {
  height: 100px;
  // max-width: 100px;
  overflow: hidden;
}

.slider-outer {
  position: relative;
  height: 550px;
  width: 550px;
  background-color: #514d6a;
}

.center-eir-img {
  max-height: 100px;
  max-width: 100px;
  display: inline-flex;
  justify-content: center;
}

.tile-header h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notificationView-text {
  font-size: 18px;
}

.notification-text {
  font-size: 14px;
}

.unReadNotification {
  background: #e4e3ff;
}

.notificationCount {
  margin-top: -30px;
  font-size: 14px;
  font-weight: bold;
}

.notification-date {
  font-size: 13px;
}

.sortableHelper {
  z-index: 10000;
}

.red {
  background: #ff4444;
}

.box {
  float: left;
  height: 15px;
  width: 15px;
  clear: both;
}

.yellow {
  background: #fff176;
}

.cpointer {
  cursor: pointer;
}

.signIn-content {
  overflow: auto;
}

.signInInputs {
  width: 100%;
  height: 2%;
}

.signInLabels {
  margin: 14px 0;
  color: black;
  font-size: 14px;
}

.signInContainer {
  background: rgb(242, 244, 248);
  color: #514d6a;
}

.login-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #514d6a;
}

.mamas-logo-text {
  font-size: 1.8rem;
  font-weight: 600;
  color: #514d6a;
  font-family: Nunito Sans, sans-serif !important;
}

.terminal-logo-text {
  font-size: 1.5rem;
  color: #514d6a;
  font-family: Nunito Sans, sans-serif !important;
}

.signIn-form {
  background-color: #fff;
  border-radius: 8px;
  font-family: Nunito Sans, sans-serif !important;
  font-size: 1rem;
  padding: 3.84rem 3.07rem 1.53rem;
}

.filterText {
  text-align: left;
  font-size: 14px;
  padding-left: 35px;
  padding-top: 4px;
}

.eir-in-view-dialog {
  width: 400px;
}

.filterInputText {
  text-align: left;
  font-size: 14px;
  padding-left: 35px;
  padding-top: 20px;
}

#reportTable td {
  text-align: left;
}

.clickedBtn {
  background: rgba(76, 175, 80, 0.7) !important;
}

.card-header.tile-header.py-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.dashboard-btn {
  width: 130px;
  height: 10px;
}

.cro-block-icon {
  font-size: 18px;
}

.mb-32 {
  margin-bottom: 32px;
}

#reportTable th {
  text-align: left;
}

.slimScrollDiv .nav-menu {
  width: 95% !important;
  padding-bottom: 50px;
}

.date-label {
  margin-bottom: 32px;
}

.cro-details {
  padding-left: 5px;
}

.cro-view-table {
  display: inline-block;
}

.cro-title {
  text-align: center;
}

.cro-equipment-table {
  display: inline-block;
}

.cro-equipment-table table>* {
  text-align: center;
}

.add-cro-text {
  margin-top: 25px;
}

.add-cro-input {
  margin-bottom: 14px;
}

.cro-equipment-add-table>* {
  margin-top: 10px;
  text-align: center;
}

.cro-hidden-button {
  visibility: hidden;
}

.equipment-add-button {
  color: cornflowerblue;
}

.delete-equipment-btn {
  padding: 4px !important;
  min-width: 10px !important;
  min-height: 0px !important;
}

.DateAndTimePick .react-datepicker__time-list {
  padding: 0px;
}

.uppercase input {
  text-transform: uppercase;
}

.recharts-default-tooltip {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.bg-card-indigo {
  background-color: #3f51b5;
}

.bg-card-pink {
  background-color: #ff4081;
}

.bg-card-sky-blue {
  background-color: #00bcd4;
}

.bg-card-light-orange {
  background-color: #ff9800;
}

.bg-card-light-blue {
  background-color: #5797fc;
}

.bg-card-light-purple {
  background-color: #9c27b0;
}

.bg-card-light-green {
  background-color: #9acd32;
}

.bg-card-grass-green {
  background-color: #4caf50;
}

.bg-card-danger-red {
  background-color: #f44336;
}

.bg-card-yellow {
  background-color: #ffd600;
}

.card-count {
  font-size: 24px;
  font-weight: 900;
}

.service-status-popup {
  height: 200px;
  overflow: hidden;
}

.add-cro-equipment-popup,
.revert-container-method,
.edit-container-modal {
  height: 400px;
  overflow: hidden !important;
}

.depots-transporters-driver-popup {
  overflow: hidden !important;
  height: 300px;
}

.min-w-70 {
  min-width: 70px;
}

.min-w-80 {
  min-width: 80px;
}

.min-w-90 {
  min-width: 90px;
}

.min-w-100 {
  min-width: 100px;
}

.min-w-120 {
  min-width: 120px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-200 {
  min-width: 200px;
}

.pt-20 {
  padding-top: 20px;
}

.report-container {
  width: 100%;
  overflow-x: auto;
  white-space: pre-line;
}

.ServiceStatusTarget {
  margin-top: 15px;
}

.page-limit-z-2 {
  z-index: 2;
}

.img-eirin {
  display: inline;
}

.img-category {
  display: flex;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.cmt-w-100 input {
  width: 100% !important;
}

.height-75 {
  height: 75px;
}

.height-40 {
  height: 40px;
}

.width-95 {
  width: 95px;
}

.table th,
.table td {
  padding: 0.5rem 0.75rem;
}

.grecaptcha-badge {
  z-index: 9999;
}

.signInDisable {
  width: 100%;
  pointer-events: none;
  opacity: 0.3;
  position: relative;
  border-radius: 15px;
  z-index: 100;
}

.signInBlockOverlay {
  height: 150px;
  width: 200px;
  z-index: 1;
  text-align: center;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.dashboardBtn {
  font-weight: 900;
  width: '100%';
  display: 'flex';
  text-align: center;
  padding: '9px 16px 9px 32px';
  margin-left: 150
}